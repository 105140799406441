import { Box, Link } from '@chakra-ui/layout';
import { Flex } from '@chakra-ui/react';
import GraphQLImage from '@components/Image/GraphQLImage';
import { HeaderNavigation } from '@components/Navigation';

const HeaderLogo: React.FC<HeaderNavigation['logo']> = ({
  logoUrl,
  mobileLogo,
  mobileLogoMaxWidth,
  logoMaxWidth,
  logoAlt,
  logo: desktopLogo,
}) => {
  const logos = (
    <>
      {mobileLogo && (
        <Box display={{ base: 'flex', xl: 'none' }} maxW={mobileLogoMaxWidth}>
          <GraphQLImage
            src={mobileLogo}
            alt={logoAlt}
            height={40}
            width={Number(mobileLogoMaxWidth) ?? 40}
            data-testid="mobileLogo"
          />
        </Box>
      )}
      {desktopLogo && (
        <Box display={{ base: 'none', xl: 'flex' }} maxW={logoMaxWidth}>
          <GraphQLImage
            src={desktopLogo}
            alt={logoAlt}
            height={32}
            width={Number(logoMaxWidth) ?? 200}
            data-testid="desktopLogo"
          />
        </Box>
      )}
    </>
  );
  return logoUrl ? (
    <Link href={logoUrl} display="flex" flexShrink={0} justifyContent="center">
      {logos}
    </Link>
  ) : (
    <Flex flexShrink={0} justifyContent="center">
      {logos}
    </Flex>
  );
};

export default HeaderLogo;
